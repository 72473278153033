<template>
  <van-nav-bar
    :title="title"
    left-arrow
    :border="false"
    @click-left="onClickLeft"
  />
</template>

<script>
export default {
  computed: {
    title() {
      return this.$route.meta.title || ''
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style></style>
