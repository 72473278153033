<template>
  <div class="container remianPage">
    <NavBar class="navbar" />
    <div class="desc">
      说明：单个时长卡到期后，其卡内剩余的未消耗的时长将作废。
    </div>
    <div class="box-header" :style="imgbg">
      <div
        class="effect-on"
        :style="{ color: expireFlag === 0 ? '#fff' : '#777' }"
        @click="onEffect(0)"
      >
        生效中
      </div>
      <div
        class="effect-off"
        :style="{ color: expireFlag === 1 ? '#fff' : '#777' }"
        @click="onEffect(1)"
      >
        已失效
      </div>
    </div>
    <div class="box">
      <Empty v-if="!list.length"></Empty>
      <van-list
        v-else
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        style="height: 100%"
        @load="onLoad"
      >
        <van-cell v-for="(item, index) in list" :key="index">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #title>
            <div class="title-box">
              <div>
                <span class="title"
                  >剩余：{{
                    item.remainSeconds
                      ? calcHourMin(item.remainSeconds)
                      : '0 分钟'
                  }}</span
                >
                <span class="title-tag" v-if="item.priorityDeduct"
                  >优先扣除</span
                >
              </div>
              <div class="title-2" v-if="item.expireFlag === 0">生效中</div>
              <div class="title-1" v-if="item.expireFlag === 1">已失效</div>
            </div>
          </template>
          <template #label>
            <div class="label-box">
              <span class="time">到期时间：{{ item.expireTime }}</span>
              <span class="cardName">{{ item.goodsName }}</span>
            </div>
          </template>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Empty from '@/components/Empty.vue'
import { remianTimeList } from '@/api/user.js'
import { calcHourMin } from '@/utils/index.js'
export default {
  components: {
    NavBar,
    Empty
  },
  data() {
    return {
      loading: false,
      expireFlag: 0,
      imgbg: {
        backgroundImage:
          'url(' + require('../../assets/images/effect-1.png') + ')'
      },
      finished: false,
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      list: []
    }
  },
  created() {
    this.page.pageNum = 1
    this.getList()
  },
  methods: {
    calcHourMin,
    onEffect(expireFlag) {
      this.expireFlag = expireFlag
      if (expireFlag === 0) {
        this.imgbg.backgroundImage =
          'url(' + require('../../assets/images/effect-1.png') + ')'
      } else if (expireFlag === 1) {
        this.imgbg.backgroundImage =
          'url(' + require('../../assets/images/effect-2.png') + ')'
      }
      this.loading = true
      this.finished = false
      this.page.pageNum = 1
      this.list = []
      this.getList()
    },
    onLoad() {
      this.page.pageNum++
      this.getList()
    },
    async getList() {
      const { pageNum, pageSize } = this.page
      const params = {
        pageNum,
        pageSize,
        expireFlag: this.expireFlag
      }
      const res = await remianTimeList(params)
      if (res.status === 200) {
        const records = res.data.list
        this.loading = false
        this.page.total = res.data.totalItems
        if (records == null || records.length === 0) {
          this.finished = true
          return
        }
        this.list = this.list.concat(records)
        if (this.list.length >= this.page.total) {
          this.finished = true
        }
      } else {
        this.$toast.error('请求数据失败')
      }
    }
  }
}
</script>

<style lang="less" scoped>
// .navbar {
//   position: fixed;
//   width: 100%;
//   top: 0;
//   left: 0;
// }
.remianPage {
  // padding-top: 44px;
  box-sizing: border-box;
}
.desc {
  box-sizing: border-box;
  padding: 0 15px;
  margin-top: 10px;
  font-size: 12px;
  color: @text-color-2;
}
.box-header {
  width: 343px;
  height: 51px;
  margin: auto;
  margin-top: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0 0;
  .effect-on,
  .effect-off {
    width: 50%;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
}
.box {
  width: 343px;
  margin: auto;
  height: calc(100vh - 44px - 88px);
  overflow: auto;
  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 10px;
    .title {
      font-size: 14px;
      font-weight: 500;
      color: @text-color-1;
      line-height: 20px;
    }
    .title-tag {
      width: 52px;
      height: 18px;
      border: 1px solid #cb7e0e;
      font-size: 10px;
      color: #cb7e0e;
      text-align: center;
      line-height: 18px;
      margin-left: 12px;
    }
    .title-2 {
      font-size: 14px;
      font-weight: 500;
      color: #cb7e0e;
    }
    .title-1 {
      font-size: 14px;
      font-weight: 500;
      color: #777777;
    }
  }
  .label-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .time {
      font-size: 13px;
      color: @text-color-2;
    }
    .cardName {
      font-size: 14px;
      color: @text-color-1;
      font-weight: 500;
    }
  }
}
</style>
