<template>
  <van-empty :description="desc">
    <template slot="image">
      <img src="../assets/icon/empty-icon.png" />
    </template>
  </van-empty>
</template>

<script>
export default {
  name: 'Empty',
  props: {
    desc: {
      type: String,
      default: '暂无数据'
    }
  }
}
</script>

<style lang="less" scoped>
.van-empty {
  height: 100%;
}
</style>
