function getParamsNew(key) {
  var temData = new URLSearchParams(window.location.search)
  return temData.get(key)
}

// 秒 to 时 分
const calcHourMin = function (...args) {
  // type s:秒  m:分
  let [second, type = 's'] = args
  // console.log(second, type, args)
  let min
  if (type === 's') {
    min = Math.ceil(second / 60)
  } else {
    min = second
  }
  if (min < 60) {
    return min + '分钟'
  } else {
    let hour = Math.floor(min / 60)
    let minutes = min % 60
    return hour + '小时' + minutes + '分钟'
  }
}

function envFn() {
  const localRegexp =
    /((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])(?::(?:[0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))|(localhost)/
  const devRegexp = /d-/
  const testRegexp = /t-/
  if (localRegexp.test(location.href)) {
    console.log(1)
    // 本地
    return 'https://t-qyydn.zimocloud.com/login'
  } else if (devRegexp.test(location.href)) {
    // dev
    return 'https://t-qyydn.zimocloud.com/login'
  } else if (testRegexp.test(location.href)) {
    // test
    return 'https://t-qyydn.zimocloud.com/login'
  } else {
    return 'https://qyydn.zimocloud.com/login'
  }
}

export { getParamsNew, calcHourMin, envFn }
